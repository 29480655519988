import React from "react";
import Layout from "../../components/layout";
import Seo from "../../components/seo";
//import { StaticImage } from "gatsby-plugin-image";
import { useIntl, Link } from "gatsby-plugin-react-intl";
//import Slider from "react-slick";
//import ComponentMain2 from "../../components/main/component-main-2";
//import ComponentTreatmentPnd from "../../components/main/component-treatment-pnd";
//import ComponentTextblock4 from "../../components/component-textblock-4";
//import ComponentColumn2 from "../../components/component-column-2";
//import ComponentPics2 from "../../components/pics/component-pics-2";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import "react-accessible-accordion/dist/fancy-example.css";

const TreatmentCvd = ({ data, location }) => {
  const intl = useIntl();

  //const [isToggleOpen, setToggleOpen] = useState([]);
  //const handleToggle = (e) => {
  //  setToggleOpen({
  //    ...isToggleOpen,
  //    [e.currentTarget.id]: isToggleOpen[e.currentTarget.id] ? false : true,
  //  });
  //};
  //const settings = {
  //  dots: true,
  //  infinite: false,
  //  speed: 500,
  //  //autoplay: true,
  //  autoplaySpeed: 4000,
  //  arrows: false,
  //  slidesToShow: 1,
  //  //centerPadding: '100px',
  //  focusOnSelect: true,
  //  //centerMode: true,
  //  variableWidth: true,
  //  responsive: [
  //    {
  //      breakpoint: 896,
  //      settings: {
  //        centerMode: false,
  //      },
  //    },
  //  ],
  //};

  return (
    <Layout location={location} locale={intl.locale} mode="bls">
      <Seo
        title={
          intl.locale === "en"
            ? `FAQ - Frequently Asked Questions - Cyberdyne`
            : `FAQ - Frequently Asked Questions - Cyberdyne`
        }
        description={
          intl.locale === "en"
            ? `Browse through these FAQs to find answers to commonly raised questions. If you cannot find one, feel free to contact us!`
            : `Sehen Sie sich unsere FAQs an und finden Sie Antworten auf allgemeine Fragen. Ist die passende Antwort nicht dabei, kontaktieren Sie uns gerne!`
        }
        ogImage="https://images.ctfassets.net/t2ifj85iosf3/Gx3Sc0gyL2KygjlYoItex/6e87f29d8f2062cd9c58ba8cbb82fbfc/E169.jpg?w=1200&h=630&fit=fill"
      />

      <section className="sect sect_TreatmentCvd_first sect_TreatmentCvd_faq sect-bg-bls pt-0">
        <div className="main_back sect-bg-bls"></div>
        <div className="main_copy_noimage">
          <h1 className="h1 txt txt-white">FAQs</h1>
          <p className="message-label-bold txt txt-rot">
            {intl.locale === "en"
              ? `Frequently Asked Questions (FAQ)`
              : `Häufig gestellte Fragen (FAQ)`}
          </p>
          <h2 className="txt txt-white h3 mt-7">
            {intl.locale === "en"
              ? `Help desk - your questions answered`
              : `Auskunft - Ihre Fragen beantwortet`}
          </h2>
        </div>
      </section>

      <section className="sect sect-faq bg-bls">
        <div className="container">
          <Accordion>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en" ? `What is HAL?` : `Was ist HAL?`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `HAL stands for Hybrid Assistive Limb and the system is a neuronal-controlled cyborg, which is used for the treatment of patients with cerebral vascular disease, spinal cord injuries,progressive intractable neuromuscular disease, and other brain-neuromuscular diseases. The neuronal-controlled HAL robot suit is used for neuromuscular feedback therapy. The command to execute a movement is transmitted from the brain through the spinal cord to the patient’s muscle which results in the muscle being tensioned to execute the movement. With patients suffering from neurogenic gait disorders this signal chain is partially impaired so that an independent initiation of movement is only possible to a limited degree. However, the residual neuromuscular impulses of the patient are picked up by sensors and transmitted to the HAL system. The system detects the impulses and provides the patient with the required power assistance in order to execute the desired movement`
                    : `HAL steht für Hybrid Assistive Limb. Das System ist ein neuronal kontrollierter Cyborg, der für die Therapie von Patienten mit zerebralen Gefäßerkrankungen, Rückenmarksverletzungen, progressiven neuromuskulären Erkrankungen und anderen Gehirn-/ neuromuskulären Erkrankungen, genutzt wird. Der neuronal gesteuerte HAL Roboteranzug wird zur neuromuskulären Feedbacktherapie eingesetzt. Der Befehl einer Bewegungsausführung wird durch das Rückenmark vom Gehirn zum Muskel gesendet und führt dort zu einer Anspannung des Muskels, die die Bewegung auslöst. Bei Patienten mit neurologischen Gangstörungen ist die beschriebene Befehlskette teilweise gestört und eine eigenständige Bewegungsintention ist nur bis zu einem gewissen Grad möglich. Die verbleibenden neuromuskulären Impulse des Patienten werden durch Sensoren aufgenommen und an das HAL-System übermittelt. Das System erkennt die Impulse und stellt dem Patienten die nötige Kraftunterstützung zur Verfügung, um die gewünschte Bewegung auszuführen.`}
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `What can I expect from HAL treatment?`
                    : `Was kann ich vom HAL Training erwarten?`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `The aim of the HAL therapy is to increasingly activate muscle
  impulses and with this to enable interactive neuronal feedback
  loop. The therapeutic successes are a result of this feedback
  effect. Within the course of the training the ability to walk
  is improved as regular repetition helps patients to regain
  their motion patterns.`
                    : `Die Absicht des HAL-Trainings ist es, die Aktivierung von Muskelimpulsen zu steigern und so die wechselwirksame neuronale Feedbackschleife zu ermöglichen. Therapeutische Erfolge beruhen auf diesem Feedbackeffekt. Durch die wiederholte Bewegung werden Bewegungmuster wieder neu erlernt und so die Gehfähigkeit verbessert.`}
                </p>
                <p>
                  {intl.locale === "en"
                    ? `For many patients it will be possible to walk with a rollator,
  on crutches or even without crutches. The neuromuscular
  feedback therapy leads to a significant gain in muscular
  strength. Other effects which can be more or less pronounced
  include: Reduction of neuropathic pains, improved bladder and
  bowl functions, positive improved spasticity symptoms, larger
  areas with skin sensation resulting in a lower decubitus risk.
  Patients continuing to actively use their regained mobility in
  everyday life will retain the progress they have made by the
  end of the neuromuscular feedback therapy.`
                    : `  Für viele Patienten ist es wieder möglich, mit Hilfe eines Rollators oder mit Gehhilfen oder sogar ohne Hilfsmittel zu laufen. Des Weiteren führt die neuromuskuläre Feedbacktherapie zu einer signifikanten Verbesserung der muskulären Kraft. Andere Effekte, die mehr oder weniger ausgeprägt sein können, sind: Abnahme neuropathischer Schmerzen, verbesserte Blasen- und Darmfunktion, positive Entwicklung von spastischen Symptomen, größere sensible Hautbereiche, die zu Reduktion des Dekubitusrisikos führen. Die, durch die neuromuskuläre Feedbacktherapie erlangten Verbesserungen bleiben erhalten, wenn der Patient die neuen Fähigkeiten weiter aktiv im Alltag einsetzt.`}
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `How can I participate in HAL therapy?`
                    : `Wie kann ich an der HAL-Therapie teilnehmen?`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Neuromuscular feedback therapy in paraplegic patients requires
  weak neurological residual impulses in the muscles that can be
  picked up on surface of skin via electrodes sensor. As a
  paralysed patient, please contact us or ask your attending
  physician or physical therapist if you still have a residual
  activity in the muscles relevant for the hip and knee joints.`
                    : `Für die neuromuskuläre Feedbacktherapie bei querschnittsgelähmten Patienten wird ein schwacher neurologischer Restimpuls im Muskel benötigt. Dieser muss von der Hautoberfläche mittels Elektroden aufgegriffen werden können. Als querschnittsgelähmter Patient kontaktieren Sie uns oder fragen Ihren Physiotherapeuten, ob eine Restaktivität in den Knie- und Hüftmuskeln vorhanden ist.`}
                </p>
                <p>
                  {intl.locale === "en"
                    ? `Your medical records then need to be forwarded to the facility
  providing HAL therapy services, where an initial medical
  evaluation will take place.`
                    : `Die Arztberichte müssen dann der Einrichtung die das HAL-Training anbietet, zur medizinischen Beurteilung zugeschickt werden.`}
                </p>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `Can I use HAL at home?`
                    : `Kann ich HAL zu Hause nutzen?`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `HAL must be used at medical facilities while under the
  supervision of trained medical professionals.If you have
  special needs to use HAL at home, please contact us directly..`
                    : `HAL kann nur in einer medizinischen Einrichtung unter Anleitung einer geschulten medizinischen Fachkraft genutzt werden. Kontaktieren Sie uns bitte, wenn Sie HAL zu Hause nutzen wollen.`}
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `How long does the treatment take?`
                    : `Wie lange dauert die HAL-Therapie?`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `The time it takes for effects to show may vary among patients.
  Most patients begin to see improvements after a handful of
  sessions while continuously showing benefits over the course
  of a few months.`
                    : `Der Zeitraum, bis Effekte durch die Therapie erkennbar werden, variiert je nach Patient. Die meisten sehen erste positive Effekte bereits nach einigen Einheiten, die sich kontinuierlich über den Zeitraum von wenigen Monaten weiter verbessern.`}
                </p>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `Does my insurance pays?`
                    : `Zahl meine Versicherung die Therapie?`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `In Germany, people who are insured under the Statutory Worker
  Compensatation insurance (Berufsgenossenschaften) is covered
  by the insurance as well as the private insurace, the
  Nürnberger insurance. If you are insured under these
  insurances, please contanct your insurance for cost coverage.`
                    : `"In Deutschland werden die Kosten von Patienten übernommen, die über die Berufsgenossenschaft oder über die private Versicherung der Nürnberger Versicherungen versichert sind. Trifft dies auf Sie zu, kontaktieren Sie bitte Ihre Versicherung für die Kostenübernahme.`}
                </p>
                <p>
                  {intl.locale === "en"
                    ? `Other facilities may provide the HAL treatment program
  out-of-pocket. Please talk with your healthcare provider and
  your insurance company.`
                    : `Andere bieten die HAL-Therapie möglicherweise mittels Zuzahlungen an. Bitte sprechen Sie mit Ihrem Leistungserbringer und Ihrer Versicherung.`}
                </p>
                <div></div>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `What can I do if my doctor does not know about HAL?`
                    : `Was kann ich tun, falls mein Arzt noch nichts von HAL gehört hat?`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `If your healthcare provider does not know about HAL, please
  introduce this website and put us in contact with them. The
  Cyberdyne team will be happy to assist you with introducing
  this technology to them.`
                    : `Sollte ihr Arzt nichts über HAL wissen, dann weisen Sie Ihn auf diese Website hin und stellen Sie den Kontakt zu uns her. Das Team von Cyberdyne freut sich, Ihnen dabei zu helfen diese Technologie zu erklären.`}
                </p>
                <div></div>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </section>

      <section className="sect sect-qa bg-bge">
        <div className="container">
          <p className="h2 mb-5 txt txt-rot">
            {intl.locale === "en"
              ? `Learn more about HAL Treatment`
              : `Mehr über das HAL Training erfahren`}
          </p>
          <Accordion>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `Cerebrovascular disease`
                    : `Zerebrovaskulären Erkrankungen`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `From the perspective of motor learning, repetitive movement
  and voluntary exercise are important elements`
                    : `Aus Sicht des motorischen Lernen sind wiederholte Bewegungen und willkürlich durchgeführte Aufgaben wichtige Elemente`}
                </p>
                <Link to="/treatment/cvd/" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `Spinal Cord Injury`
                    : `Rückenmarksverletzungen`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Locomotor training performed using a neurologically controlled
  cyborg HAL enables the neural activity and repetitive
  excercises of specific tasks.`
                    : `Lokomotionstraining mit dem neurologisch kontrollierten Cyborg HAL ermöglicht neuronale Aktivität und wiederholte Durchführung spezifischer Aufgaben.`}
                </p>
                <Link to="/treatment/spinal/" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>

            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `Progressive neuromuscular diseases`
                    : `Progressive neuromuskuläre Erkrankungen`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `The 5 years Post-marketing surveillance results suggest that
  treatment with the HAL system shows improvement in gait
  related outcome measures despite the progressive nature of the
  diseases.`
                    : `Beobachtungen 5 Jahre nach Einführung des HALs zeigen, dass die HAL-Therapie zu Verbesserungen in Testungen in Bezug auf die Gehfähigkeit führt, trotz der fortschreitenden Art der Erkrankung.`}
                </p>
                <Link to="/treatment/pnd/" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en"
                    ? `About ZNB - Our Therapist`
                    : `Das ZNB / Unsere Therapeuten`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Meet our team. We offer exceptional level of personalised
  skill and expertise.`
                    : `Treffen Sie unser Team. Wie bieten ein außergewöhnliches Level an individuellen Fähigkeiten und Fachwissen. `}
                </p>
                <Link to="/treatment/znb/" className="link-button">
                  {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                </Link>
              </AccordionItemPanel>
            </AccordionItem>
            <AccordionItem>
              <AccordionItemHeading className="h1">
                <AccordionItemButton>
                  {intl.locale === "en" ? `Testimonials` : `Erfahrungsberichte`}
                </AccordionItemButton>
              </AccordionItemHeading>
              <AccordionItemPanel>
                <p>
                  {intl.locale === "en"
                    ? `Discover real stories from real people about how Cyberdyne
  technology changed their lives forever.`
                    : `Entdecken Sie reale Geschichten von Patienten darüber wie Cyberdyne deren Leben für immer verändert hat.`}
                </p>
                <div>
                  <Link to="/treatment/testimonials/" className="link-button">
                    {intl.locale === "en" ? `Learn more` : `Mehr erfahren`}
                  </Link>
                </div>
              </AccordionItemPanel>
            </AccordionItem>
          </Accordion>
        </div>
      </section>

      <section className="sect sect_TreatmentCvd_2f8as2">
        <div className="container">
          <p className="h3 font-weight-bold">
            {intl.locale === "en" ? `More Questions?` : `Weitere Fragen?`}
          </p>
          <p>
            {intl.locale === "en"
              ? `Whether you are patients, family of patients, medical professionals,
  researcher, or medical distributors, if you are interested in
  getting to know more of Cybernics, HAL treatment, HAL system, and/or
  simply our company, please send us inquiry.`
              : `Ob Sie nun Patient, Familie eines Patienten, medizinische Fackraft, Wissenschaftler oder medizinischer Vertriebspartner sind und daran interessiert sind, mehr über Cybernics, HAL-Therapie und das HAL-System oder unser Unternehmen zu erfahren, schreiben Sie uns einfach eine Nachricht.`}
          </p>
          <div>
            <Link to="/contact/" className="link-button">
              {intl.locale === "en" ? `Get in Touch` : `Kontaktieren Sie uns`}
            </Link>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default TreatmentCvd;
